import axios from "axios";
import Cookies from 'js-cookie';

// const hashKey= process.env.REACT_APP_SECRET_KEY;

const apiPath = process.env.REACT_APP_APIURL;

const apiInstance = axios.create({
    baseURL: apiPath,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiInstance.interceptors.request.use(config => {
    const token = Cookies.get('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    } 
    // else {
    //     // Handle case where token is not found
    //     setLoginSuccess(false);
    //     throw new axios.Cancel('Token not found');
    // }
    return config;
}, error => {
    return Promise.reject(error);
});



export const apiClient = {
    apiInstance
};
