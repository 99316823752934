import React from 'react'
import PropTypes from 'prop-types'
import { apiLoginUtilities } from '../framework/login_api'
import Cookies from 'js-cookie';
const hashKey= process.env.REACT_APP_SECRET_KEY;

export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null
}
let count=0;
export const ContextProvider = (props) => {
  const [state, setState] = React.useState({
    isLoggedIn: false,
    isLoginPending: false,
    loginError: null
  });
  const userData = localStorage.getItem('user_auth');


  const setLoginPending = (isLoginPending) => {setState({isLoginPending})};
  const setLoginSuccess = (isLoggedIn) => {setState({isLoggedIn})};
  const setLoginError = (loginError) => {setState({loginError})};
  
  React.useEffect(()=>{
    if(userData !== null){
      setLoginSuccess(true)
    }
  }, [])
  const updateError=()=>{
    setLoginError(null);
  }

  const login = (email, password, captchaText) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);
    fetchLogin( email, password, captchaText, error => {
      setLoginPending(false);
      if (!error) {
        setLoginSuccess(true);
        window.location.href = "/admin/dashboard";
      } else {
        if(error.response.data.msg=='Incorrect username and password.'){
          setLoginError("Invalid Credentials, Try again!");
        }
        
      }
    })
  }

  const logout = () => {
    logoutHit( error => {
      if (!error) {
        setLoginPending(false);
        setLoginSuccess(false);
        setLoginError(null);
        Cookies.remove('isAuthenticated');
        Cookies.remove('token');
        window.location.href = "/";
      } else {
        setLoginError(error);
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        updateError,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
ContextProvider.propTypes = {
    children: PropTypes.any,
}
const fetchLogin = (email, password, captchaText, callback) => {
  apiLoginUtilities.login(email, password, captchaText).then((data)=>{
        if(data.statusCode === true){  
          Cookies.set('isAuthenticated', true, { expires: 1 / 24 });
          Cookies.set('token', data.response.token, { expires: 1 / 24 });
          return callback(null);
        }else{
            return callback("Unauthorized Access");
        }
        }, error=>{
          return callback(error);
    })
}
const logoutHit =(callback)=>{
  apiLoginUtilities.logout().then((data)=>{
    if(data.statusCode === true){ 
      return callback(null);
    }else{
      return callback("something went wrong");
    }
    }, error=>{
        console.log(error);
})
}
