import axios from "axios"
import CryptoJS from 'crypto-js';
import {apiClient} from './api_client';
const hashKey= process.env.REACT_APP_SECRET_KEY;
const apiPath = process.env.REACT_APP_APIURL

const decryptData = (name) => {
  const encrypted = localStorage.getItem(name);
  const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
}
async function login(email, password, captchaText){
    const url = apiPath+"admins/login";
    const exactDateTimestamp = new Date().getTime();
    const jsonData ={
        email: email,
        password: password,
        captchaTxt: captchaText,
        hash: exactDateTimestamp
    }
    const tokenVal = CryptoJS.AES.encrypt(JSON.stringify(jsonData), hashKey).toString();
    const body ={
        token: tokenVal
    }
    const result = await axios.post(url, body);
    return result.data;
}
async function signup(values){
    const url = apiPath+"admins/signup";
    const jsonData ={
        user_name: values.user_name,
        email: values.email,
        password: values.password
    }
    const tokenVal = CryptoJS.AES.encrypt(JSON.stringify(jsonData), hashKey).toString();
    const params ={
        token: tokenVal
    }
    const result = await axios.post(url,params);
    return result.data;
}
async function updateprofile(values,id){
    const url = apiPath+"admins/"+id;
    const jsonData ={
        user_name: values.user_name,
        password: values.password
    }
    const tokenVal = CryptoJS.AES.encrypt(JSON.stringify(jsonData), hashKey).toString();
    const params ={
        token: tokenVal
    }
    const result = await axios.put(url,params);
    return result.data;
}
async function getProfile(id){
    const url = apiPath+"admin/"+id;
    const result = await apiClient.apiInstance.get(url);
    return result.data.response;
}
async function logout(){
    const url = apiPath+"admins/logout";
    const result = await apiClient.apiInstance.post(url);
    return result.data;
}

export const apiLoginUtilities = {
    decryptData,
    login,
    signup,
    updateprofile,
    getProfile,
    logout
}
