import React, { Suspense, useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { AuthContext } from './context/Auth.context.js';

const Home = React.lazy(() => import('./views/Home/index'))
const AboutUs = React.lazy(() => import('./views/aboutus/index'))
const WebsitePolicy = React.lazy(() => import('./views/websitepolicy/index'))
const Help = React.lazy(() => import('./views/help/index'))
const Sitemap = React.lazy(() => import('./views/sitemap/index'))
const Feedback = React.lazy(() => import('./views/feedback/index'))
const RelatedLink = React.lazy(() => import('./views/relatedlinks/index'))
const ContactUs = React.lazy(() => import('./views/contactus/index'))
const Infrastructure = React.lazy(() => import('./views/infrastructure/index'))
const Archives = React.lazy(() => import('./views/archives/index'))
const Documents = React.lazy(() => import('./views/documents/index'))
const Resources = React.lazy(() => import('./views/resources/index'))
const Offerings = React.lazy(() => import('./views/offerings/index'))
const IssRefresher = React.lazy(() => import('./views/issrefresher/index'))
const Login = React.lazy(() => import('./views/login/index'))
const AdminLayout = React.lazy(() => import('./views/admin/adminlayout/index'))
const Dashboard =  React.lazy(() => import('./views/admin/dashboard/index'))
const OfferingsAdmin =  React.lazy(() => import('./views/admin/offerings/index'))
const Whoswhoadmin =  React.lazy(() => import('./views/admin/whoswho/index'))
const Whoswholist =  React.lazy(() => import('./views/admin/whoswholist/index'))
const AdminBatch =  React.lazy(() => import('./views/admin/batch/index'))
const AdminBatcheslist =  React.lazy(() => import('./views/admin/batchlist/index'))
const AdminOrganisation =  React.lazy(() => import('./views/admin/organisation/index'))
const AdminOrganisationlist =  React.lazy(() => import('./views/admin/organisationlist/index'))
const AdminTraining =  React.lazy(() => import('./views/admin/training/index'))
const AdminTraininglist =  React.lazy(() => import('./views/admin/traininglist/index'))
const AdminParticipants = React.lazy(() => import('./views/admin/participants/index'))
const AdminParticipantslist =  React.lazy(() => import('./views/admin/participantslist/index'))
const Signup =  React.lazy(() => import('./views/signup/index'))
const AdminDocuments = React.lazy(() => import('./views/admin/documents/index'))
const AdminDocumentList = React.lazy(() => import('./views/admin/documentlist/index'))
const AdminAuditLogsList =  React.lazy(() => import('./views/admin/auditlogs/index'))
const AdminProfile = React.lazy(() => import('./views/admin/profile/index'))

const isAuthenticated = () => {
  // Replace this with real authentication check logic
  return Cookies.get('isAuthenticated') === "true";
};
const PrivateRoute = ({ element, path }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};
function App() {
  const { state } = useContext(AuthContext);
 // const Navigate = useNavigate();
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/website-policy" element={<WebsitePolicy />} />
          <Route path="/archives" element={<Archives />} />
          <Route path="/help" element={<Help />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/related-links" element={<RelatedLink />} />
          <Route path="/connect" element={<ContactUs />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/offerings" element={<Offerings />} />
          <Route path="/iss-refresher" element={<IssRefresher />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          
          {/* Admin routes */}
          <Route path="/admin" element={<PrivateRoute element={<AdminLayout />} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="batch" element={<AdminBatch />} />
            <Route path="batch/:id" element={<AdminBatch />} />
            <Route path="batchlist" element={<AdminBatcheslist />} />
            <Route path="organisation" element={<AdminOrganisation />} />
            <Route path="organisation/:id" element={<AdminOrganisation />} />
            <Route path="organisationlist" element={<AdminOrganisationlist />} />
            <Route path="training" element={<AdminTraining />} />
            <Route path="training/:id" element={<AdminTraining />} />
            <Route path="traininglist" element={<AdminTraininglist />} />
            <Route path="participants" element={<AdminParticipants />} />
            <Route path="participants/:id" element={<AdminParticipants />} />
            <Route path="participantslist" element={<AdminParticipantslist />} />
            <Route path="offerings" element={<OfferingsAdmin />} />
            <Route path="whoswho" element={<Whoswhoadmin />} />
            <Route path="whoswho/:id" element={<Whoswhoadmin />} />
            <Route path="whoswholist" element={<Whoswholist />} />
            <Route path="documents" element={<AdminDocuments />} />
            <Route path="documents/:id" element={<AdminDocuments />} />
            <Route path="documentlist" element={<AdminDocumentList />} />
            <Route path="auditlogs" element={<AdminAuditLogsList />} />
            <Route path="profile" element={<AdminProfile />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App