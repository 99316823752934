import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import { ContextProvider } from './context/Auth.context.js';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
createRoot(document.getElementById('root')).render(
  <ContextProvider value={500}>
    <Provider store={store}>
        <App />
        <ToastContainer autoClose={3000} />
    </Provider>
  </ContextProvider>,
)
